<template>
	<section>
		<ProductLabelBulkAttachSectionDetail title="Label">
			<template v-slot:description>
				Select label that need to attach with the selected product <br>from the next section.
			</template>
			<template v-slot:actions>
				<CButton
					color="secondary"
					@click="handleButtonAddClick"
				>
					Add label
				</CButton>
			</template>
		</ProductLabelBulkAttachSectionDetail>

		<span v-if="!isValid" class="danger mt-1">
			{{ $t('global.error.required') }}
		</span>

		<ProductLabelPreviewList
			v-if="labels.length"
			:type="type"
			:list="labels"
			class="mt-3"
			@onDelete="handleDelete"
		/>

		<ModalProductLabel
			ref="modal-label"
			@onSubmit="handleSubmitModal"
		/>
	</section>
</template>

<script>
import ProductLabelBulkAttachSectionDetail from '@/components/ProductLabelBulkAttachSectionDetail.vue';
import ProductLabelPreviewList from '@/components/ProductLabelPreviewList.vue';
import ModalProductLabel from '@/components/ModalProductLabel.vue';
import {
	PRODUCT_LABEL_TYPES,
} from '../enums/productLabels';

export default {
	name: 'ProductLabelBulkAttachLabel',
	components: {
		ProductLabelBulkAttachSectionDetail,
		ProductLabelPreviewList,
		ModalProductLabel,
	},
	props: {
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
		labels: {
			type: Array,
			default: () => [],
		},
		isValid: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		handleButtonAddClick() {
			this.$refs['modal-label'].open(this.type, null, this.labels);
			this.$emit('onOpenModal');
		},
		handleSubmitModal({ labels = [] }) {
			this.$emit('update:labels', labels);
		},
		handleDelete({ id = null }) {
			if (id) {
				const deletedLabels = this.labels.filter((label) => label.id !== id);
				this.$emit('update:labels', deletedLabels);
			}
		},
	},
};
</script>
