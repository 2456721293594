<template>
	<CRow>
		<CCol sm="8">
			<h4 class="mb-1">{{ title }}</h4>
			<div class="typo-body-2 color-black-45">
				<slot name="description"></slot>
			</div>
		</CCol>
		<CCol sm="4" class="text-right">
			<slot name="actions"></slot>
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'ProductLabelBulkAttachSectionDetail',
	props: {
		title: {
			type: String,
			default: '',
		},
	},
};
</script>
