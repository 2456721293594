<template>
	<div class="main-wrapper mt-4 mb-3">
		<ProductLabelBulkAttachLabel
			:type="type"
			:labels.sync="$v.selectedLabels.$model"
			:is-valid="!$v.selectedLabels.$error"
			@update:labels="handleResetError"
			@onOpenModal="handleResetError"
		/>

		<hr>

		<ProductLabelBulkAttachSku
			:type="type"
			:skus.sync="$v.selectedSkus.$model"
			:is-valid="!$v.selectedSkus.$error"
			@update:skus="handleResetError"
			@onOpenModal="handleResetError"
		/>

		<hr class="mb-5">

		<BaseActionPanelStickyFooter
			button-edit-confirm-text="Attach label"
			is-edit
			@onCancel="handleCancel"
			@onConfirm="handleConfirm"
		/>

		<BaseModalConfirm
			ref="modal-confirm"
			title="Continue to attach the label?"
			description="All changes will take effect immediately and can not be undone."
			primary-button-text="Attach"
			primary-button-loading-text="Attaching"
			@onConfirm="handleSubmit"
		/>
		<BaseModalLoading ref="modal-loading" modal-title="Attaching" />
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import ProductLabelBulkAttachLabel from '@/components/ProductLabelBulkAttachLabel.vue';
import ProductLabelBulkAttachSku from '@/components/ProductLabelBulkAttachSku.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelBulkAttach',
	validations: {
		selectedLabels: {
			required: (value) => value.length,
		},
		selectedSkus: {
			required: (value) => value.length,
		},
	},
	components: {
		ProductLabelBulkAttachLabel,
		ProductLabelBulkAttachSku,
		BaseModalConfirm,
	},
	data() {
		return {
			selectedLabels: [],
			selectedSkus: [],
		};
	},
	computed: {
		type() {
			switch (this.$route.query.type) {
				case PRODUCT_LABEL_TYPES.primary:
					return PRODUCT_LABEL_TYPES.primary;
				case PRODUCT_LABEL_TYPES.frame:
					return PRODUCT_LABEL_TYPES.frame;
				case PRODUCT_LABEL_TYPES.secondary:
					return PRODUCT_LABEL_TYPES.secondary;
				case PRODUCT_LABEL_TYPES.badge:
					return PRODUCT_LABEL_TYPES.badge;
				default:
					return null;
			}
		},
		selectedLabelIds() {
			return this.selectedLabels.map((item) => item.id);
		},
		selectedSkusJoined() {
			return this.selectedSkus.join(';');
		},
	},
	created() {
		this.setPageTitle({
			title: `Bulk attach ${this.type} label`,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			showToast: 'toast/showToast',
			putProductLabelBulkAttach: 'productLabels/putProductLabelBulkAttach',
		}),
		handleConfirm() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.$refs['modal-confirm'].open();
			}
		},
		async handleSubmit() {
			this.$refs['modal-loading'].open();

			let toast = {
				content: this.$t('page.productLabels.bulkAttach.successMessage'),
				header: this.$t('global.successMessageTitle'),
				type: 'success',
			};

			try {
				await this.putProductLabelBulkAttach({
					label_ids: this.selectedLabelIds,
					skus: this.selectedSkusJoined,
				});

				this.$router.push({
					name: 'ProductLabels',
					query: { type: this.type },
				});
			} catch {
				toast = {
					content: this.$t('global.errorMessage'),
					header: this.$t('page.productLabels.bulkAttach.errorMessageTitle'),
					type: 'danger',
				};
			} finally {
				this.$refs['modal-loading'].close();
				this.showToast(toast);
			}
		},
		handleCancel() {
			this.$router.push({
				name: 'ProductLabels',
				query: { type: this.type },
			});
		},
		handleResetError() {
			this.$v.$reset();
		},
	},
};
</script>

<style lang="scss" scoped>
	hr {
		margin: rem(40) 0;
	}
</style>
