<template>
	<section>
		<ProductLabelBulkAttachSectionDetail title="SKU" class="mb-3">
			<template #description>
				Select SKU or product that need to attach with the selected <br>label from above section.
			</template>
			<template #actions>
				<CButton
					color="secondary"
					@click="handleButtonAddClick"
				>
					Add SKU
				</CButton>
			</template>
		</ProductLabelBulkAttachSectionDetail>

		<div v-if="!skus.length">
			<BaseNoItemBanner
				:class="{ 'is-invalid': !isValid }"
				text="There are no SKU for this attaching"
			/>
			<div v-if="!isValid" class="danger text-error">
				{{ $t('global.error.required') }}
			</div>
		</div>
		<div v-else class="list-item show-border">
			<p class="typo-body-2 color-black-45">{{ skuListText }}</p>
		</div>

		<ModalSkuList
			ref="modal-sku"
			title="Add SKU"
			add-button-text="Add"
			:sku-list="skus"
			@onConfirm="handleSubmitModal"
		/>
	</section>
</template>

<script>
import ProductLabelBulkAttachSectionDetail from '@/components/ProductLabelBulkAttachSectionDetail.vue';
import ModalSkuList from '@/components/ModalSkuList.vue';
import {
	PRODUCT_LABEL_TYPES,
} from '../enums/productLabels';

export default {
	name: 'ProductLabelBulkAttachSku',
	components: {
		ProductLabelBulkAttachSectionDetail,
		ModalSkuList,
	},
	props: {
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
		skus: {
			type: Array,
			default: () => [],
		},
		isValid: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		skuListText() {
			return this.skus.join('; ');
		},
	},
	methods: {
		handleButtonAddClick() {
			this.$refs['modal-sku'].open();
			this.$emit('onOpenModal');
		},
		handleSubmitModal(skus = []) {
			this.$emit('update:skus', skus);
		},
	},
};
</script>

<style lang="scss" scoped>
	.is-invalid {
		border: 1px solid $color-alert;
		border-radius: rem(4);
	}

	.list-item {
		padding: rem(20) rem(16) 0;
		max-height: rem(320);
		overflow: auto;
	}

	.show-border {
		border: solid 1px $color-gray-400;
		border-radius: rem(4);
	}

	.text-error {
		margin-top: rem(12);
	}
</style>
