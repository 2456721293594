<template>
	<div>
		<ul v-if="activeList.length" class="label-preview active-list">
			<li
				v-for="item in activeList"
				:key="item.id"
				class="label-preview-item"
			>
				<ProductLabelPreviewListItem
					:type="type"
					:data="item"
					@onDelete="handleDelete"
				/>
			</li>
		</ul>
		<div v-if="inactiveList.length" class="inactive-list-wrapper">
			<h6 class="typo-label color-black-45 mb-1">
				Inactive label
			</h6>
			<ul class="label-preview inactive-list">
				<li
					v-for="item in inactiveList"
					:key="item.id"
					class="label-preview-item"
				>
					<ProductLabelPreviewListItem
						:type="type"
						:data="item"
						@onDelete="handleDelete"
					/>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import ProductLabelPreviewListItem from './ProductLabelPreviewListItem.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelPreviewList',
	components: {
		ProductLabelPreviewListItem,
	},
	props: {
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		activeList() {
			return this.list.filter((label) => label.isActive);
		},
		inactiveList() {
			return this.list.filter((label) => !label.isActive);
		},
	},
	methods: {
		handleDelete(data) {
			this.$emit('onDelete', data);
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-preview {
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;

		// .label-preview-item
		&-item {
			display: inline-block;
			margin-right: rem(16);
			margin-bottom: rem(12);
		}
	}

	.active-list {
		margin-bottom: rem(-12);
	}

	.inactive-list {
		background-color: $color-gray-100;
		border: 1px solid $color-gray-400;
		border-radius: 4px;
		padding: rem(12) rem(12) 0 rem(12);
	}

	.active-list + .inactive-list-wrapper {
		margin-top: rem(16);
	}
</style>
