<template>
	<div :class="[`label-preview-list-item type-${type}`, { 'badge-item': type === PRODUCT_LABEL_TYPES.badge }]">
		<div v-if="type === PRODUCT_LABEL_TYPES.badge">
			<ProductLabelBadge :label="data.titleEN" :color="data.fontColor" />
		</div>
		<template v-else>
			<img :src="data.thumbnail" :alt="data.name">
		</template>
		<CButton
			class="btn-delete-label"
			@click="handleDelete(data.id)"
		>
			<CIcon name="cil-trash" />
		</CButton>
	</div>
</template>

<script>
import ProductLabelBadge from '@/components/ProductLabelBadge.vue';
import { PRODUCT_LABEL_TYPES } from '../enums/productLabels';

export default {
	name: 'ProductLabelPreviewListItem',
	components: {
		ProductLabelBadge,
	},
	props: {
		type: {
			type: String,
			default: PRODUCT_LABEL_TYPES.primary,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			PRODUCT_LABEL_TYPES,
		};
	},
	methods: {
		handleDelete(id) {
			this.$emit('onDelete', { id, type: this.type });
		},
	},
};
</script>

<style lang="scss" scoped>
	.label-preview-list-item {
		width: rem(60);
		height: rem(60);
		position: relative;
		border-radius: 2px;
		border: 1px solid $color-gray-300;

		&.type-secondary {
			width: rem(234);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.btn-delete-label {
		position: absolute;
		right: rem(4);
		bottom: rem(4);
		width: rem(20);
		height: rem(20);
		box-shadow: none;
		padding: 0;
		border-radius: 2px;
		background-color: $color-black-45;

		svg {
			width: rem(12) !important;
			color: $color-white;
		}
	}

	.badge-item {
		padding: rem(8);
		display: inline-flex;
		align-items: center;
		gap: rem(8);
		width: 100%;
		height: fit-content;
		min-width: rem(80);

		.btn-delete-label {
			position: unset;
		}
	}
</style>
